/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.6.2
 *
*/

// Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en");

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


// Variables, Mixins
@import "import/variables";
@import "import/mixins";

// INSPINIA Theme Elements
@import "import/typography";
@import "import/navigation";
@import "import/top_navigation";
@import "import/buttons";
@import "import/badgets_labels";
@import "import/elements";
@import "import/sidebar";
@import "import/base";
@import "import/pages";
@import "import/chat";
@import "import/metismenu";
@import "import/plugins";
@import "import/spinners";

@import "import/skins";
@import "import/md-skin";

// Media query style
@import "import/media";

// Properties
@import "import/properties";

// Slider
@import "import/slider";

// Custom style
// Your custom style to override base style
@import "import/custom";

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }
  body { overflow: visible !important; }

  #page-wrapper {
    margin: 0;
  }
}
